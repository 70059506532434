*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  /*overflow-y: hidden;*/
}

html body {
  padding: 0;
  margin: 0;
  /*overflow-y: inherit;*/
}

.reactour__helper {

  padding: 15px 15px !important;
}
