@font-face {
    font-family: 'Averta';
    src: url('Averta-BoldItalic.woff2') format('woff2'),
    url('Averta-BoldItalic.woff') format('woff'),
    url('Averta-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-Bold.woff2') format('woff2'),
    url('Averta-Bold.woff') format('woff'),
    url('Averta-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-BlackItalic.woff2') format('woff2'),
    url('Averta-BlackItalic.woff') format('woff'),
    url('Averta-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-Black.woff2') format('woff2'),
    url('Averta-Black.woff') format('woff'),
    url('Averta-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-LightItalic.woff2') format('woff2'),
    url('Averta-LightItalic.woff') format('woff'),
    url('Averta-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-ExtraBold.woff2') format('woff2'),
    url('Averta-ExtraBold.woff') format('woff'),
    url('Averta-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-Light.woff2') format('woff2'),
    url('Averta-Light.woff') format('woff'),
    url('Averta-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-ExtraBoldItalic.woff2') format('woff2'),
    url('Averta-ExtraBoldItalic.woff') format('woff'),
    url('Averta-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-SemiboldItalic.woff2') format('woff2'),
    url('Averta-SemiboldItalic.woff') format('woff'),
    url('Averta-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta-Extrathin';
    src: url('Averta-Extrathin.woff2') format('woff2'),
    url('Averta-Extrathin.woff') format('woff'),
    url('Averta-Extrathin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta-ExtrathinItalic';
    src: url('Averta-ExtrathinItalic.woff2') format('woff2'),
    url('Averta-ExtrathinItalic.woff') format('woff'),
    url('Averta-ExtrathinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta-RegularItalic';
    src: url('Averta-RegularItalic.woff2') format('woff2'),
    url('Averta-RegularItalic.woff') format('woff'),
    url('Averta-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-Regular.woff2') format('woff2'),
    url('Averta-Regular.woff') format('woff'),
    url('Averta-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-Semibold.woff2') format('woff2'),
    url('Averta-Semibold.woff') format('woff'),
    url('Averta-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-Thin.woff2') format('woff2'),
    url('Averta-Thin.woff') format('woff'),
    url('Averta-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('Averta-ThinItalic.woff2') format('woff2'),
    url('Averta-ThinItalic.woff') format('woff'),
    url('Averta-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

